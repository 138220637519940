const Other = [
        {
          "brand": "LEXUS",
          "model": "IS",
          "series": "GXE10",
          "dateRange": "99~05",
          "ikoma": "FR",
          "": "",
          "kyb": 341262,
          "__1": "PCL-130*",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "IS",
          "series": "GXE10",
          "dateRange": "99~05",
          "ikoma": "RR",
          "": "",
          "kyb": 341263,
          "__1": "PCL-273",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "IS",
          "series": "GSE20 RWD",
          "dateRange": "05~13",
          "ikoma": "FR",
          "": "PCI-213",
          "kyb": 551130,
          "__1": "",
          "__2": 551131
        },
        {
          "brand": "LEXUS",
          "model": "IS",
          "series": "GSE25 AWD",
          "dateRange": "05~13",
          "ikoma": "FR",
          "": "Inquiry",
          "kyb": 551126,
          "__1": "",
          "__2": 551127
        },
        {
          "brand": "LEXUS",
          "model": "IS",
          "series": "GSE20/25",
          "dateRange": "05~13",
          "ikoma": "RR",
          "": "PCI-214",
          "kyb": 551132,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "ES",
          "series": "MCV30L",
          "dateRange": "01~06",
          "ikoma": "FR",
          "": "",
          "kyb": 334386,
          "__1": "PCL-260",
          "__2": 334387
        },
        {
          "brand": "LEXUS",
          "model": "ES",
          "series": "MCV30L",
          "dateRange": "01~06",
          "ikoma": "RR",
          "": "",
          "kyb": 334388,
          "__1": "PCL-261",
          "__2": 334389
        },
        {
          "brand": "LEXUS",
          "model": "ES",
          "series": "GSV40L",
          "dateRange": "06~12",
          "ikoma": "FR",
          "": "",
          "kyb": 339023,
          "__1": "PCL-354",
          "__2": 339024
        },
        {
          "brand": "LEXUS",
          "model": "ES",
          "series": "GSV40L",
          "dateRange": "06~12",
          "ikoma": "RR",
          "": "",
          "kyb": 339059,
          "__1": "PCL-355",
          "__2": 339060
        },
        {
          "brand": "LEXUS",
          "model": "ES",
          "series": "#V50",
          "dateRange": "12~",
          "ikoma": "FR",
          "": "",
          "kyb": 335090,
          "__1": "PCL-334",
          "__2": 335091
        },
        {
          "brand": "LEXUS",
          "model": "ES",
          "series": "#V50",
          "dateRange": "12~",
          "ikoma": "RR",
          "": "",
          "kyb": 335092,
          "__1": "PCL-335",
          "__2": 335093
        },
        {
          "brand": "LEXUS",
          "model": "GX",
          "series": "##J120 GX470",
          "dateRange": "02~",
          "ikoma": "FR",
          "": "PCI-179",
          "kyb": 341344,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "GX",
          "series": "##J120 GX470",
          "dateRange": "02~",
          "ikoma": "RR",
          "": "PCI-176",
          "kyb": 344416,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "GX",
          "series": "##J120 GX470",
          "dateRange": "02~",
          "ikoma": "RR",
          "": "PCI-220",
          "kyb": 344410,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "GX",
          "series": "##J150 GX460",
          "dateRange": "08~",
          "ikoma": "FR",
          "": "Inquiry",
          "kyb": 340085,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "GX",
          "series": "##J150 GX460",
          "dateRange": "08~",
          "ikoma": "RR",
          "": "Inquiry",
          "kyb": 349185,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "LS",
          "series": "UCF30",
          "dateRange": "00~06",
          "ikoma": "FR",
          "": "PCI-030",
          "kyb": 341392,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "LS",
          "series": "UCF30",
          "dateRange": "00~06",
          "ikoma": "RR",
          "": "PCI-031",
          "kyb": 341393,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "ACU/MCU/SCU 10(2WD)(4WD)",
          "dateRange": "97/12~03/02",
          "ikoma": "FR",
          "": "",
          "kyb": 334261,
          "__1": "PCL-328",
          "__2": 334262
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "ACU/MCU/SCU 10 (2WD)",
          "dateRange": "97/12~03/02",
          "ikoma": "RR",
          "": "",
          "kyb": 334269,
          "__1": "PCL-329",
          "__2": 334270
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "ACU/MCU/SCU 15 (4WD)",
          "dateRange": "97/12~03/02",
          "ikoma": "RR",
          "": "",
          "kyb": 334263,
          "__1": "PCL-331",
          "__2": 334264
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "ACU/MCU 30",
          "dateRange": "03/02~",
          "ikoma": "FR",
          "": "",
          "kyb": 334399,
          "__1": "PCL-366",
          "__2": 334400
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "(48510-48090)",
          "dateRange": "03/02~",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-368",
          "__2": ""
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "ACU/MCU 30",
          "dateRange": "03/02~",
          "ikoma": "RR",
          "": "",
          "kyb": 334394,
          "__1": "PCL-367",
          "__2": 334395
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "ACU/MCU 30 FWD",
          "dateRange": "03/02~",
          "ikoma": "RR",
          "": "",
          "kyb": 339208,
          "__1": "Inquiry",
          "__2": 339209
        },
        {
          "brand": "LEXUS",
          "model": "RX",
          "series": "AGL10W",
          "dateRange": "09~15",
          "ikoma": "FR",
          "": "",
          "kyb": 339281,
          "__1": "Inquiry",
          "__2": 339282
        },
        {
          "brand": "MITSUBISHI",
          "model": "COLT PLUS",
          "series": "Z23W",
          "dateRange": "04~",
          "ikoma": "FR",
          "": "",
          "kyb": 333476,
          "__1": "PCL-500",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "COLT PLUS",
          "series": "Z23W",
          "dateRange": "04~",
          "ikoma": "RR",
          "": "",
          "kyb": 343490,
          "__1": "PCL-501",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "GALANT /LEGNUM",
          "series": "EA#A /W (2WD) incl Estate",
          "dateRange": "96/10~",
          "ikoma": "FR",
          "": "",
          "kyb": 341141,
          "__1": "PCL-516",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "GALANT /LEGNUM",
          "series": "EA#A /W (2WD) incl Estate",
          "dateRange": "96/10~",
          "ikoma": "RR",
          "": "",
          "kyb": 341213,
          "__1": "PCL-517",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "GALANT /GRUNDER",
          "series": "ES",
          "dateRange": "04~",
          "ikoma": "FR",
          "": "",
          "kyb": 334432,
          "__1": "PCL-540",
          "__2": 334433
        },
        {
          "brand": "MITSUBISHI",
          "model": "GALANT /GRUNDER",
          "series": "ES",
          "dateRange": "04~",
          "ikoma": "RR",
          "": "",
          "kyb": 341373,
          "__1": "PCL-541",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "L200 / FORTE / STRADA / TRITON",
          "series": "KA5T 4WD (MR992320)",
          "dateRange": "05~",
          "ikoma": "FR",
          "": "",
          "kyb": 340033,
          "__1": "PCL-502",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "L200 / FORTE / STRADA / TRITON",
          "series": "KA5T 4WD",
          "dateRange": "05~",
          "ikoma": "RR",
          "": "PCI-089",
          "kyb": 343251,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "L400/SPACE GEAR",
          "series": "PD4W,PD5W(4WD)",
          "dateRange": "94/06~",
          "ikoma": "FR",
          "": "PCI-132",
          "kyb": "",
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "L400/SPACE GEAR",
          "series": "PD4W,PD5W(4WD)",
          "dateRange": "94/06~",
          "ikoma": "RR",
          "": "PCI-133",
          "kyb": "",
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "LANCER/LANCER CEDIA",
          "series": "CS5A(MR519614)",
          "dateRange": "00/09~",
          "ikoma": "FR",
          "": "",
          "kyb": 333381,
          "__1": "PCL-076",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "LANCER/LANCER CEDIA",
          "series": "CS5A(MN101958)",
          "dateRange": "00/12~03",
          "ikoma": "FR",
          "": "",
          "kyb": 334369,
          "__1": "PCL-078",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "LANCER/LANCER CEDIA",
          "series": "CS5A",
          "dateRange": "00/09~",
          "ikoma": "RR",
          "": "",
          "kyb": 341318,
          "__1": "PCL-077",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "LANCER/LANCER CEDIA",
          "series": "CY2A/CY4A",
          "dateRange": "07~",
          "ikoma": "FR",
          "": "",
          "kyb": 339104,
          "__1": "PCL-564",
          "__2": 339105
        },
        {
          "brand": "MITSUBISHI",
          "model": "LANCER/LANCER CEDIA",
          "series": "CY2A/CY4A",
          "dateRange": "07~",
          "ikoma": "RR",
          "": "",
          "kyb": 341444,
          "__1": "PCL-565",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "OUTLANDER/AIRTREK",
          "series": "CU2W",
          "dateRange": "02~07",
          "ikoma": "FR",
          "": "",
          "kyb": 334368,
          "__1": "PCL-560",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "OUTLANDER/AIRTREK",
          "series": "CU2W",
          "dateRange": "02~07",
          "ikoma": "RR",
          "": "",
          "kyb": 341348,
          "__1": "PCL-077*",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "OUTLANDER/AIRTREK",
          "series": "CU2W",
          "dateRange": "02~07",
          "ikoma": "RR",
          "": "",
          "kyb": 341361,
          "__1": "PCL-559",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "OUTLANDER/PEUGEOT 4007",
          "series": "2.0/2.2/2.4",
          "dateRange": "07~14",
          "ikoma": "FR",
          "": "",
          "kyb": 339080,
          "__1": "PCL-570",
          "__2": 339081
        },
        {
          "brand": "MITSUBISHI",
          "model": "OUTLANDER/PEUGEOT 4007",
          "series": "2.0/2.2/2.4",
          "dateRange": "07~14",
          "ikoma": "RR",
          "": "PCI-173",
          "kyb": 341454,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "OUTLANDER/PEUGEOT 4007",
          "series": "2.0/2.2/2.4",
          "dateRange": "07~14",
          "ikoma": "RR",
          "": "",
          "kyb": 349040,
          "__1": "PCL-571",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO",
          "series": "V1#V/W,V3#V/W/V2#C/W,V4#W",
          "dateRange": "91/01~99/08",
          "ikoma": "FR",
          "": "PCI-118",
          "kyb": 344222,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO",
          "series": "V1#V/W,V3#V/W/V2#C/W,V4#W",
          "dateRange": "91/01~99/08",
          "ikoma": "RR",
          "": "PCI-117",
          "kyb": 343239,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO",
          "series": "V2#C/W,V4#W",
          "dateRange": "91/12~99/08",
          "ikoma": "RR",
          "": "PCI-119",
          "kyb": 344223,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO",
          "series": "V6#W;V7#W",
          "dateRange": "99/08~06",
          "ikoma": "FR",
          "": "PCI-120",
          "kyb": 341251,
          "__1": "PCL-562",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO",
          "series": "V6#W;V7#W",
          "dateRange": "99/08~06",
          "ikoma": "RR",
          "": "PCI-121",
          "kyb": 344300,
          "__1": "PCL-563",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO",
          "series": "V80",
          "dateRange": "07��",
          "ikoma": "FR",
          "": "PCI-120X",
          "kyb": 341445,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO",
          "series": "V80",
          "dateRange": "07��",
          "ikoma": "RR",
          "": "PCI-121X",
          "kyb": 349057,
          "__1": "PCL-563",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO io/PAJERO PININ",
          "series": "H6#W;H7#W",
          "dateRange": "98/08~",
          "ikoma": "FR",
          "": "",
          "kyb": 334405,
          "__1": "PCL-120",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO io/PAJERO PININ",
          "series": "H6#W;H7#W",
          "dateRange": "98/08~",
          "ikoma": "RR",
          "": "PCI-115",
          "kyb": 343408,
          "__1": "PCL-121",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO SPORT",
          "series": "K80;K85W,K86W for General",
          "dateRange": "99/06~02/05",
          "ikoma": "FR",
          "": "PCI-118",
          "kyb": 344294,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "PAJERO SPORT",
          "series": "K80;K85W,K86W for General",
          "dateRange": "99/06~02/05",
          "ikoma": "RR",
          "": "PCI-177",
          "kyb": 343427,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "MITSUBISHI",
          "model": "SPACE WAGON/",
          "series": "N84W (2WD), N94W\n  (4WD)",
          "dateRange": "98/07~00/05",
          "ikoma": "FR",
          "": "",
          "kyb": 334235,
          "__1": "PCL-556",
          "__2": 334236
        },
        {
          "brand": "MITSUBISHI",
          "model": "SPACE WAGON/",
          "series": "N84W (2WD), N94W\n  (4WD)",
          "dateRange": "98/07~00/05",
          "ikoma": "RR",
          "": "",
          "kyb": 343312,
          "__1": "PCL-557",
          "__2": ""
        },
        {
          "brand": "INFINITI",
          "model": "FX35",
          "series": "S50",
          "dateRange": "03~08",
          "ikoma": "FR",
          "": "",
          "kyb": 339055,
          "__1": "PCL-408",
          "__2": 339056
        },
        {
          "brand": "INFINITI",
          "model": "FX35",
          "series": "S50",
          "dateRange": "03~08",
          "ikoma": "RR",
          "": "PCI-216",
          "kyb": 349027,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "ACURA",
          "model": "MDX",
          "series": "YD1",
          "dateRange": "03~08",
          "ikoma": "FR",
          "": "",
          "kyb": 334364,
          "__1": "PCL-812",
          "__2": 334365
        },
        {
          "brand": "ACURA",
          "model": "MDX",
          "series": "YD1",
          "dateRange": "03~08",
          "ikoma": "RR",
          "": "PCI-235",
          "kyb": 344353,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "ACURA",
          "model": "MDX",
          "series": "YD2",
          "dateRange": "08~13",
          "ikoma": "FR",
          "": "",
          "kyb": 339037,
          "__1": "Inquiry",
          "__2": 339038
        },
        {
          "brand": "ACURA",
          "model": "MDX",
          "series": "YD2",
          "dateRange": "08~13",
          "ikoma": "RR",
          "": "Inquiry",
          "kyb": 349025,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "ISUZU",
          "model": "BIGHORN/TROOPER",
          "series": "UBS69",
          "dateRange": "92~",
          "ikoma": "FR",
          "": "PCI-136",
          "kyb": "",
          "__1": "",
          "__2": ""
        },
        {
          "brand": "ISUZU",
          "model": "BIGHORN/TROOPER",
          "series": "UBS69",
          "dateRange": "92~",
          "ikoma": "RR",
          "": "PCI-135",
          "kyb": 344229,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "ISUZU",
          "model": "D-MAX / RODEO",
          "series": "4WD",
          "dateRange": "13~",
          "ikoma": "FR",
          "": "Inquiry",
          "kyb": 340107,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "ISUZU",
          "model": "D-MAX / RODEO",
          "series": "4WD",
          "dateRange": "13~",
          "ikoma": "RR",
          "": "Inquiry",
          "kyb": 349221,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "SUBARU",
          "model": "FORESTER",
          "series": "SF5 2.0 (4WD)",
          "dateRange": "97~02/01",
          "ikoma": "FR",
          "": "",
          "kyb": 334189,
          "__1": "PCL-938",
          "__2": 334190
        },
        {
          "brand": "SUBARU",
          "model": "FORESTER",
          "series": "SF5 2.0 (4WD)",
          "dateRange": "97~02/01",
          "ikoma": "RR",
          "": "",
          "kyb": 334191,
          "__1": "PCL-939",
          "__2": 334192
        },
        {
          "brand": "SUBARU",
          "model": "FORESTER",
          "series": "SG5 2.0",
          "dateRange": "02~07",
          "ikoma": "FR",
          "": "",
          "kyb": 334342,
          "__1": "PCL-940",
          "__2": 334343
        },
        {
          "brand": "SUBARU",
          "model": "FORESTER",
          "series": "SG5 2.5",
          "dateRange": "02~07",
          "ikoma": "FR",
          "": "",
          "kyb": 334370,
          "__1": "PCL-942",
          "__2": 334371
        },
        {
          "brand": "SUBARU",
          "model": "FORESTER",
          "series": "SG5 2.0",
          "dateRange": "02~07",
          "ikoma": "RR",
          "": "",
          "kyb": 334344,
          "__1": "PCL-941",
          "__2": 334345
        },
        {
          "brand": "SUBARU",
          "model": "FORESTER",
          "series": "SH5",
          "dateRange": "08~",
          "ikoma": "FR",
          "": "",
          "kyb": 339169,
          "__1": "Inquiry",
          "__2": 339170
        },
        {
          "brand": "SUBARU",
          "model": "FORESTER",
          "series": "SH5",
          "dateRange": "08~",
          "ikoma": "RR",
          "": "",
          "kyb": 341486,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "APV",
          "series": "GC415",
          "dateRange": "06~",
          "ikoma": "FR",
          "": "",
          "kyb": 338096,
          "__1": "Inquiry",
          "__2": 338097
        },
        {
          "brand": "SUZUKI",
          "model": "GRAND VITARA",
          "series": "TA# TD#",
          "dateRange": "06~",
          "ikoma": "FR",
          "": "",
          "kyb": 334464,
          "__1": "PCL-724",
          "__2": 334465
        },
        {
          "brand": "SUZUKI",
          "model": "GRAND VITARA",
          "series": "TA# TD#",
          "dateRange": "06~",
          "ikoma": "RR",
          "": "",
          "kyb": 343435,
          "__1": "PCL-725",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "GRAND VITARA/ESCUDO",
          "series": "SQ416",
          "dateRange": "98~",
          "ikoma": "FR",
          "": "",
          "kyb": 634089,
          "__1": "PCL-716",
          "__2": 634090
        },
        {
          "brand": "SUZUKI",
          "model": "GRAND VITARA/ESCUDO",
          "series": "SQ416",
          "dateRange": "98~",
          "ikoma": "RR",
          "": "PCI-134",
          "kyb": 343247,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "GRAND VITARA/ESCUDO",
          "series": "H27A 2.7 V6 4WD",
          "dateRange": "01/07~",
          "ikoma": "FR",
          "": "",
          "kyb": 334195,
          "__1": "PCL-716",
          "__2": 334196
        },
        {
          "brand": "SUZUKI",
          "model": "GRAND VITARA/ESCUDO",
          "series": "H27A 2.7 V6 4WD",
          "dateRange": "01/07~",
          "ikoma": "RR",
          "": "PCI-214",
          "kyb": 344440,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "LIANA / AERIO",
          "series": "ALL",
          "dateRange": "04~",
          "ikoma": "FR",
          "": "",
          "kyb": 333431,
          "__1": "PCL-722",
          "__2": 333432
        },
        {
          "brand": "SUZUKI",
          "model": "LIANA / AERIO",
          "series": "ALL",
          "dateRange": "04~",
          "ikoma": "RR",
          "": "",
          "kyb": 333356,
          "__1": "PCL-723",
          "__2": 333357
        },
        {
          "brand": "SUZUKI",
          "model": "JIMNY",
          "series": "SN413",
          "dateRange": "98~",
          "ikoma": "FR",
          "": "PCI-233",
          "kyb": 343287,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "JIMNY",
          "series": "SN413",
          "dateRange": "98~",
          "ikoma": "RR",
          "": "PCI-224",
          "kyb": 343288,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "SOLIO/WAGON R",
          "series": "MA34/63/64",
          "dateRange": "00/06~",
          "ikoma": "FR",
          "": "",
          "kyb": 333306,
          "__1": "PCL-700",
          "__2": 333307
        },
        {
          "brand": "SUZUKI",
          "model": "SOLIO/WAGON R",
          "series": "MA34/63/64",
          "dateRange": "00/06~",
          "ikoma": "RR",
          "": "",
          "kyb": 342027,
          "__1": "PCL-701",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "CARRY / EVERY LANDY",
          "series": "DA63T (41601-67H11)",
          "dateRange": "99~13",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-708*",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "CARRY / EVERY LANDY",
          "series": "DA32W (41601-64H11)",
          "dateRange": "99~13",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-708",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "SWIFT",
          "series": "RS413",
          "dateRange": "05~",
          "ikoma": "FR",
          "": "",
          "kyb": 333425,
          "__1": "PCL-092",
          "__2": 333426
        },
        {
          "brand": "SUZUKI",
          "model": "SWIFT",
          "series": "RS413",
          "dateRange": "05~",
          "ikoma": "RR",
          "": "",
          "kyb": 343464,
          "__1": "PCL-093",
          "__2": ""
        },
        {
          "brand": "SUZUKI",
          "model": "SX4",
          "series": "ALL",
          "dateRange": "06~",
          "ikoma": "FR",
          "": "",
          "kyb": 339186,
          "__1": "Inquiry",
          "__2": 339187
        },
        {
          "brand": "SUZUKI",
          "model": "SX4",
          "series": "ALL",
          "dateRange": "06~",
          "ikoma": "RR",
          "": "",
          "kyb": 343493,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "ATOS/ATOZ",
          "series": "",
          "dateRange": "97/11~",
          "ikoma": "FR",
          "": "",
          "kyb": 333256,
          "__1": "PCL-190",
          "__2": 333257
        },
        {
          "brand": "HYUNDAI",
          "model": "ATOS/ATOZ",
          "series": "PRIME",
          "dateRange": "98/10~",
          "ikoma": "FR",
          "": "",
          "kyb": 333298,
          "__1": "PCL-192",
          "__2": 333299
        },
        {
          "brand": "HYUNDAI",
          "model": "ATOS/ATOZ",
          "series": "All",
          "dateRange": "98/10~",
          "ikoma": "RR",
          "": "",
          "kyb": 343301,
          "__1": "PCL-191",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "CLICK/GETZ/TB",
          "series": "All",
          "dateRange": "02/09~",
          "ikoma": "FR",
          "": "",
          "kyb": 333506,
          "__1": "PCL-184",
          "__2": 333507
        },
        {
          "brand": "HYUNDAI",
          "model": "CLICK/GETZ/TB",
          "series": "All",
          "dateRange": "02/09~",
          "ikoma": "RR",
          "": "PCI-190",
          "kyb": 343398,
          "__1": "PCL-185",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA/AVANT XD",
          "series": "GF-XD 18/20",
          "dateRange": "95/05~",
          "ikoma": "FR",
          "": "",
          "kyb": 333205,
          "__1": "PCL-194",
          "__2": 333206
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA/AVANT XD",
          "series": "GF-XD 18/20 (S)",
          "dateRange": "00/05~",
          "ikoma": "RR",
          "": "",
          "kyb": 333500,
          "__1": "PCL-195",
          "__2": 333501
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA/AVANT XD",
          "series": "GF-XD 18/20 (L)",
          "dateRange": "00/05~",
          "ikoma": "RR",
          "": "",
          "kyb": 333781,
          "__1": "PCL-153*",
          "__2": 333782
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA/AVANT XD",
          "series": "GF-XD 18/20",
          "dateRange": "00/05~",
          "ikoma": "RR",
          "": "",
          "kyb": "",
          "__1": "PCL-153*",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA / AVANT / I30",
          "series": "HD/FD",
          "dateRange": "07~11",
          "ikoma": "FR",
          "": "",
          "kyb": 338080,
          "__1": "PCL-258",
          "__2": 338081
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA / AVANT / I30",
          "series": "HD/FD",
          "dateRange": "07~11",
          "ikoma": "RR",
          "": "",
          "kyb": 349098,
          "__1": "PCL-259",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA / AVANT / I35",
          "series": "MD (54651-3X250)",
          "dateRange": "11~15",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "ELANTRA / AVANT / I35",
          "series": "MD (55300-3X100)",
          "dateRange": "11~15",
          "ikoma": "RR",
          "": "",
          "kyb": "",
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "GRAND STAREX / H100 / iLOAD",
          "series": "TQ (54660-4H150)",
          "dateRange": "07~",
          "ikoma": "FR",
          "": "",
          "kyb": 339398,
          "__1": "PCL-148",
          "__2": 339399
        },
        {
          "brand": "HYUNDAI",
          "model": "GRAND STAREX / H100 / iLOAD",
          "series": "TQ (55300-4H150)",
          "dateRange": "07~",
          "ikoma": "RR",
          "": "PCI-231",
          "kyb": 349166,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "MATRIX",
          "series": "1.6/1.8/1.5D",
          "dateRange": "01~",
          "ikoma": "FR",
          "": "",
          "kyb": 333366,
          "__1": "PCL-196",
          "__2": 333367
        },
        {
          "brand": "HYUNDAI",
          "model": "MATRIX",
          "series": "1.6/1.8/1.5D",
          "dateRange": "01~",
          "ikoma": "RR",
          "": "",
          "kyb": 333504,
          "__1": "PCL-197",
          "__2": 333505
        },
        {
          "brand": "HYUNDAI",
          "model": "TERRACAN",
          "series": "2.5;2.9;3.5",
          "dateRange": "01/05~",
          "ikoma": "FR",
          "": "PCI-171",
          "kyb": 344453,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "TERRACAN",
          "series": "2.5;2.9;3.5",
          "dateRange": "01/05~",
          "ikoma": "RR",
          "": "PCI-172",
          "kyb": 344454,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "TUCSON",
          "series": "2.0;2.7",
          "dateRange": "04/07~",
          "ikoma": "FR",
          "": "",
          "kyb": 334502,
          "__1": "PCL-198",
          "__2": 334503
        },
        {
          "brand": "HYUNDAI",
          "model": "TUCSON",
          "series": "2.0;2.7",
          "dateRange": "04/07~",
          "ikoma": "RR",
          "": "",
          "kyb": 334504,
          "__1": "PCL-199",
          "__2": 334505
        },
        {
          "brand": "HYUNDAI",
          "model": "TUCSON / IX35",
          "series": "Gen2",
          "dateRange": "09~",
          "ikoma": "FR",
          "": "",
          "kyb": 339402,
          "__1": "Inquiry",
          "__2": 339403
        },
        {
          "brand": "HYUNDAI",
          "model": "TUCSON / IX35",
          "series": "Gen2 AWD",
          "dateRange": "09~",
          "ikoma": "RR",
          "": "",
          "kyb": 349187,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "TUCSON / IX35",
          "series": "Gen2 FWD",
          "dateRange": "09~",
          "ikoma": "RR",
          "": "",
          "kyb": 349243,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "SONATA",
          "series": "EF",
          "dateRange": "98~05",
          "ikoma": "FR",
          "": "",
          "kyb": 341280,
          "__1": "PCL-162",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "SONATA",
          "series": "EF",
          "dateRange": "98~05",
          "ikoma": "RR",
          "": "",
          "kyb": 341281,
          "__1": "PCL-163",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "SONATA",
          "series": "NF",
          "dateRange": "04~09",
          "ikoma": "FR",
          "": "",
          "kyb": 341476,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "SONATA",
          "series": "NF",
          "dateRange": "04~09",
          "ikoma": "RR",
          "": "",
          "kyb": 349060,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "SONATA",
          "series": "YF (54651-2T022)",
          "dateRange": "09~14",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-256",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "SONATA",
          "series": "YF (55311-2T020)",
          "dateRange": "09~14",
          "ikoma": "RR",
          "": "",
          "kyb": "",
          "__1": "PCL-257",
          "__2": ""
        },
        {
          "brand": "HYUNDAI",
          "model": "SANTA FE / IX55",
          "series": "CM (54650-2B200)",
          "dateRange": "06~12",
          "ikoma": "FR",
          "": "",
          "kyb": 334506,
          "__1": "PCL-150",
          "__2": 334507
        },
        {
          "brand": "HYUNDAI",
          "model": "SANTA FE / IX55",
          "series": "CM (55310-2B201)",
          "dateRange": "06~12",
          "ikoma": "RR",
          "": "",
          "kyb": 344500,
          "__1": "PCL-151",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "K-Series TRUCK",
          "series": "54300-4E100",
          "dateRange": "",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCM-242",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "K-Series TRUCK",
          "series": "55300-4E100",
          "dateRange": "",
          "ikoma": "RR",
          "": "",
          "kyb": "",
          "__1": "PCM-240",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "PICANTO",
          "series": "1.0/1.1",
          "dateRange": "04~",
          "ikoma": "FR",
          "": "",
          "kyb": 332500,
          "__1": "PCL-140",
          "__2": 332501
        },
        {
          "brand": "KIA",
          "model": "PICANTO",
          "series": "1.0/1.1",
          "dateRange": "04~",
          "ikoma": "RR",
          "": "PCI-232",
          "kyb": 343405,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "PRIDE",
          "series": "DA",
          "dateRange": "96~",
          "ikoma": "FR",
          "": "",
          "kyb": 332054,
          "__1": "PCL-040",
          "__2": 332055
        },
        {
          "brand": "KIA",
          "model": "PRIDE",
          "series": "DA",
          "dateRange": "96~",
          "ikoma": "RR",
          "": "",
          "kyb": 341079,
          "__1": "PCL-041",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "OPTIMA / K5",
          "series": "TF",
          "dateRange": "10~15",
          "ikoma": "FR",
          "": "",
          "kyb": 339402,
          "__1": "Inquiry",
          "__2": 339403
        },
        {
          "brand": "KIA",
          "model": "OPTIMA / K5",
          "series": "TF",
          "dateRange": "10~15",
          "ikoma": "RR",
          "": "",
          "kyb": 349244,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "SORENTO",
          "series": "BL (54630-3E031)",
          "dateRange": "02~09",
          "ikoma": "FR",
          "": "",
          "kyb": 341364,
          "__1": "PCL-132",
          "__2": 341365
        },
        {
          "brand": "KIA",
          "model": "SORENTO",
          "series": "BL (55300-3031)",
          "dateRange": "02~09",
          "ikoma": "RR",
          "": "",
          "kyb": 344451,
          "__1": "PCL-133",
          "__2": 349005
        },
        {
          "brand": "KIA",
          "model": "SORENTO",
          "series": "BL (55310-3E500)",
          "dateRange": "02~09",
          "ikoma": "RR",
          "": "",
          "kyb": 349115,
          "__1": "PCL-139",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "SPORTAGE",
          "series": "JE",
          "dateRange": "04~10",
          "ikoma": "FR",
          "": "",
          "kyb": 334502,
          "__1": "PCL-198",
          "__2": 334503
        },
        {
          "brand": "KIA",
          "model": "SPORTAGE",
          "series": "JE",
          "dateRange": "04~10",
          "ikoma": "RR",
          "": "",
          "kyb": 334504,
          "__1": "PCL-199",
          "__2": 334505
        },
        {
          "brand": "KIA",
          "model": "SPORTAGE",
          "series": "SL",
          "dateRange": "09~",
          "ikoma": "FR",
          "": "",
          "kyb": 339402,
          "__1": "Inquiry",
          "__2": 339403
        },
        {
          "brand": "KIA",
          "model": "SPORTAGE",
          "series": "SL",
          "dateRange": "09~",
          "ikoma": "RR",
          "": "",
          "kyb": 349187,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "KIA",
          "model": "SPORTAGE",
          "series": "SL",
          "dateRange": "09~",
          "ikoma": "RR",
          "": "",
          "kyb": 349243,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "SSANGYONG",
          "model": "ACTYON /KYRON",
          "series": "(44310-09003)",
          "dateRange": "05~",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-136",
          "__2": ""
        },
        {
          "brand": "SSANGYONG",
          "model": "REXTON",
          "series": "(44310-08301)",
          "dateRange": "02~",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-134",
          "__2": ""
        },
        {
          "brand": "FORD",
          "model": "FOCUS 2/C-MAX",
          "series": "1.6/1.8/2.0",
          "dateRange": "04~",
          "ikoma": "FR",
          "": "",
          "kyb": 334840,
          "__1": "PCL-668",
          "__2": 334841
        },
        {
          "brand": "FORD",
          "model": "FOCUS 2/C-MAX",
          "series": "1.6/1.8/2.0",
          "dateRange": "04~",
          "ikoma": "RR",
          "": "PCI-191",
          "kyb": 343413,
          "__1": "PCL-669",
          "__2": ""
        },
        {
          "brand": "BUICK",
          "model": "REGAL",
          "series": "",
          "dateRange": "03/01~06/12",
          "ikoma": "FR",
          "": "",
          "kyb": 334227,
          "__1": "PCL-052",
          "__2": ""
        },
        {
          "brand": "BUICK",
          "model": "REGAL",
          "series": "",
          "dateRange": "03/01~06/12",
          "ikoma": "RR",
          "": "",
          "kyb": 334228,
          "__1": "PCL-053",
          "__2": ""
        },
        {
          "brand": "BUICK",
          "model": "SAIL",
          "series": "SEDAN/S-RV",
          "dateRange": "00~05/03",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-125",
          "__2": ""
        },
        {
          "brand": "BUICK",
          "model": "SAIL",
          "series": "SEDAN/S-RV",
          "dateRange": "00~05/03",
          "ikoma": "RR",
          "": "PCI-166",
          "kyb": 343349,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Chevrolet Epica/Tosca",
          "series": "OEM:96491670",
          "dateRange": "06~",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-050",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Chevrolet Epica/Tosca",
          "series": "",
          "dateRange": "06~",
          "ikoma": "RR",
          "": "",
          "kyb": "",
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Daewoo Kalos/AVEO",
          "series": "",
          "dateRange": "02~",
          "ikoma": "FR",
          "": "",
          "kyb": 333417,
          "__1": "PCL-154",
          "__2": 333418
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Daewoo Kalos/AVEO",
          "series": "",
          "dateRange": "02~",
          "ikoma": "RR",
          "": "PCI-175",
          "kyb": 343423,
          "__1": "PCL-155",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Daewoo\n  Lacetti/Excelle/HRV/Nubira/Optra",
          "series": "FR",
          "dateRange": "02~",
          "ikoma": "PCL-054",
          "": 339029,
          "kyb": 339030,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Daewoo\n  Lacetti/Excelle/HRV/Nubira/Optra",
          "series": "RR",
          "dateRange": "02~",
          "ikoma": "PCL-055",
          "": 333419,
          "kyb": 333420,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Daewoo Magnus/Evanda",
          "series": "OEM:96337535/6",
          "dateRange": "00~06",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "PCL-182",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "Daewoo Magnus/Evanda",
          "series": "OEM:96337522",
          "dateRange": "00~06",
          "ikoma": "RR",
          "": "",
          "kyb": "",
          "__1": "PCL-183",
          "__2": ""
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "MATIZ / SPARK",
          "series": "",
          "dateRange": "98~",
          "ikoma": "FR",
          "": "",
          "kyb": 332100,
          "__1": "PCL-186",
          "__2": 332101
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "MATIZ / SPARK",
          "series": "",
          "dateRange": "02~",
          "ikoma": "FR",
          "": "",
          "kyb": 332130,
          "__1": "PCL-188",
          "__2": 332131
        },
        {
          "brand": "GM\n  DAEWOO",
          "model": "MATIZ / SPARK",
          "series": "",
          "dateRange": "98~",
          "ikoma": "RR",
          "": "",
          "kyb": 343304,
          "__1": "PCL-189",
          "__2": ""
        },
        {
          "brand": "PEUGEOT",
          "model": "ALL",
          "series": "FR",
          "dateRange": "98~",
          "ikoma": "PCL-126",
          "": 333729,
          "kyb": 333730,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "PEUGEOT",
          "model": "ALL",
          "series": "RR",
          "dateRange": "98~",
          "ikoma": "PCL-127",
          "": 341250,
          "kyb": "",
          "__1": "PCI-187",
          "__2": ""
        },
        {
          "brand": "PEUGEOT",
          "model": "ALL",
          "series": "FR",
          "dateRange": "87~96",
          "ikoma": "PCL-128",
          "": 333829,
          "kyb": "",
          "__1": "",
          "__2": ""
        },
        {
          "brand": "PEUGEOT",
          "model": "ALL",
          "series": "RR",
          "dateRange": "87~96",
          "ikoma": "PCL-129",
          "": 341102,
          "kyb": "",
          "__1": "",
          "__2": 551053
        },
        {
          "brand": "OPEL",
          "model": "CORSA",
          "series": "A,B",
          "dateRange": "83~09/00",
          "ikoma": "FR",
          "": "",
          "kyb": 333831,
          "__1": "PCL-124",
          "__2": ""
        },
        {
          "brand": "OPEL",
          "model": "CORSA",
          "series": "A,B",
          "dateRange": "83~09/00",
          "ikoma": "RR",
          "": "",
          "kyb": 343265,
          "__1": "PCL-125",
          "__2": ""
        },
        {
          "brand": "VW",
          "model": "GOLF",
          "series": "MK4 1J",
          "dateRange": "97~03",
          "ikoma": "FR",
          "": "",
          "kyb": 334812,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "VW",
          "model": "GOLF",
          "series": "MK4 1J",
          "dateRange": "97~03",
          "ikoma": "RR",
          "": "",
          "kyb": 343348,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "VW",
          "model": "GOLF",
          "series": "MK5 1K od:50",
          "dateRange": "03~09",
          "ikoma": "FR",
          "": "",
          "kyb": 334834,
          "__1": "PCL-035",
          "__2": ""
        },
        {
          "brand": "VW",
          "model": "GOLF",
          "series": "MK5 1K od:55",
          "dateRange": "03~09",
          "ikoma": "FR",
          "": "",
          "kyb": 335808,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "VW",
          "model": "GOLF",
          "series": "MK5 1K",
          "dateRange": "03~09",
          "ikoma": "RR",
          "": "",
          "kyb": 344459,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "VW",
          "model": "T4",
          "series": "",
          "dateRange": "90~03",
          "ikoma": "FR",
          "": "",
          "kyb": 344261,
          "__1": "PCM-016",
          "__2": ""
        },
        {
          "brand": "VW",
          "model": "T4",
          "series": "",
          "dateRange": "90~03",
          "ikoma": "RR",
          "": "",
          "kyb": 345900,
          "__1": "PCM-017",
          "__2": ""
        }
];

export default Other;