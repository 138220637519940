const Honda = [
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "CF;CG;CH",
          "dateRange": "98/02~",
          "ikoma": "FR",
          "": "",
          "kyb": 341255,
          "__1": "PCL-822",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "CF;CG;CH",
          "dateRange": "98/02~",
          "ikoma": "RR",
          "": "",
          "kyb": 341256,
          "__1": "PCL-823",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "CL;CM",
          "dateRange": "03/02~",
          "ikoma": "FR",
          "": "",
          "kyb": 341330,
          "__1": "PCL-826",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "CL;CM",
          "dateRange": "03/02~",
          "ikoma": "RR",
          "": "",
          "kyb": 341331,
          "__1": "PCL-827",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "CU",
          "dateRange": "08~13",
          "ikoma": "FR",
          "": "",
          "kyb": 340029,
          "__1": "PCL-828",
          "__2": 340030
        },
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "CU",
          "dateRange": "08~13",
          "ikoma": "RR",
          "": "",
          "kyb": 340031,
          "__1": "PCL-829",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "",
          "dateRange": "13~",
          "ikoma": "FR",
          "": "",
          "kyb": "",
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ACCORD",
          "series": "",
          "dateRange": "13~",
          "ikoma": "RR",
          "": "",
          "kyb": "",
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "CIVIC",
          "series": "EP",
          "dateRange": "01~",
          "ikoma": "FR",
          "": "",
          "kyb": 331010,
          "__1": "PCL-810",
          "__2": 331011
        },
        {
          "brand": "HONDA",
          "model": "CIVIC",
          "series": "EP;EU;ES",
          "dateRange": "01~",
          "ikoma": "FR",
          "": "",
          "kyb": 331008,
          "__1": "PCL-808",
          "__2": 331009
        },
        {
          "brand": "HONDA",
          "model": "CIVIC",
          "series": "EP;EU;ES;EP",
          "dateRange": "01~",
          "ikoma": "RR",
          "": "",
          "kyb": 341311,
          "__1": "PCL-809",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "CIVIC",
          "series": "FD#",
          "dateRange": "06~",
          "ikoma": "FR",
          "": "",
          "kyb": 339074,
          "__1": "PCL-852",
          "__2": 339075
        },
        {
          "brand": "HONDA",
          "model": "CIVIC",
          "series": "FD#",
          "dateRange": "06~",
          "ikoma": "RR",
          "": "",
          "kyb": 343479,
          "__1": "PCL-853",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "CIVIC",
          "series": "FB",
          "dateRange": "12~",
          "ikoma": "FR",
          "": "",
          "kyb": 339277,
          "__1": "Inquiry",
          "__2": 339278
        },
        {
          "brand": "HONDA",
          "model": "CIVIC",
          "series": "FB",
          "dateRange": "12~",
          "ikoma": "RR",
          "": "",
          "kyb": 348066,
          "__1": "Inquiry",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "CR-V",
          "series": "RD1",
          "dateRange": "95/10~01/11",
          "ikoma": "FR",
          "": "",
          "kyb": 341260,
          "__1": "PCL-836",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "CR-V",
          "series": "RD1",
          "dateRange": "95/10~01/11",
          "ikoma": "RR",
          "": "",
          "kyb": 341261,
          "__1": "PCL-837",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "CR-V",
          "series": "RD5(51605-S9A-034)",
          "dateRange": "01/11~07",
          "ikoma": "FR",
          "": "",
          "kyb": 331050,
          "__1": "PCL-848",
          "__2": 331051
        },
        {
          "brand": "HONDA",
          "model": "CR-V",
          "series": "RD5(52611-S9A-N02)",
          "dateRange": "01/11~07",
          "ikoma": "RR",
          "": "",
          "kyb": 348488,
          "__1": "PCL-849",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "CR-V",
          "series": "RE3/4/5/6",
          "dateRange": "07~",
          "ikoma": "FR",
          "": "",
          "kyb": 339261,
          "__1": "PCL-838",
          "__2": 339262
        },
        {
          "brand": "HONDA",
          "model": "CR-V",
          "series": "RE3/4/5/6",
          "dateRange": "07~",
          "ikoma": "RR",
          "": "",
          "kyb": 341492,
          "__1": "PCL-839",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "FIT / JAZZ",
          "series": "GD1",
          "dateRange": "01/06~",
          "ikoma": "FR",
          "": "",
          "kyb": 333331,
          "__1": "PCL-802",
          "__2": 333332
        },
        {
          "brand": "HONDA",
          "model": "FIT / JAZZ",
          "series": "GD1",
          "dateRange": "01/06~",
          "ikoma": "RR",
          "": "PCI-170",
          "kyb": 343381,
          "__1": "PCL-803",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "FIT / JAZZ",
          "series": "GD1/3",
          "dateRange": "02/10~",
          "ikoma": "FR",
          "": "",
          "kyb": 333410,
          "__1": "PCL-850",
          "__2": 333411
        },
        {
          "brand": "HONDA",
          "model": "HR-V",
          "series": "GH",
          "dateRange": "98/09~",
          "ikoma": "FR",
          "": "",
          "kyb": 334243,
          "__1": "PCL-834",
          "__2": 334244
        },
        {
          "brand": "HONDA",
          "model": "HR-V",
          "series": "GH",
          "dateRange": "99/08~",
          "ikoma": "RR",
          "": "PCI-193",
          "kyb": 343315,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ODYSSEY",
          "series": "RA1-9 JP",
          "dateRange": "94/09~03",
          "ikoma": "FR",
          "": "",
          "kyb": 341370,
          "__1": "PCL-822",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ODYSSEY",
          "series": "RA1-9 JP",
          "dateRange": "94/09~03",
          "ikoma": "RR",
          "": "PCI-161",
          "kyb": 344274,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "ODYSSEY / LAGREAT",
          "series": "RL1 USA",
          "dateRange": "99~04",
          "ikoma": "FR",
          "": "",
          "kyb": 334317,
          "__1": "PCL-818",
          "__2": 334318
        },
        {
          "brand": "HONDA",
          "model": "ODYSSEY / LAGREAT",
          "series": "RL1 USA",
          "dateRange": "99~04",
          "ikoma": "RR",
          "": "PCI-235",
          "kyb": 344353,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "PILOT",
          "series": "",
          "dateRange": "03~08",
          "ikoma": "FR",
          "": "",
          "kyb": 334364,
          "__1": "PCL-812",
          "__2": 334365
        },
        {
          "brand": "HONDA",
          "model": "PILOT",
          "series": "",
          "dateRange": "03~08",
          "ikoma": "RR",
          "": "PCI-235",
          "kyb": 344353,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "PILOT",
          "series": "LX",
          "dateRange": "03~08",
          "ikoma": "FR",
          "": "",
          "kyb": 339361,
          "__1": "PCL-218",
          "__2": 339362
        },
        {
          "brand": "HONDA",
          "model": "PILOT",
          "series": "LX",
          "dateRange": "03~08",
          "ikoma": "RR",
          "": "Inquiry",
          "kyb": 349151,
          "__1": "",
          "__2": ""
        },
        {
          "brand": "HONDA",
          "model": "STEPWGN",
          "series": "RF3/4",
          "dateRange": "01/04~",
          "ikoma": "FR",
          "": "",
          "kyb": 339027,
          "__1": "PCL-856",
          "__2": 339028
        },
        {
          "brand": "HONDA",
          "model": "STEPWGN",
          "series": "RF3/4",
          "dateRange": "01/04~",
          "ikoma": "RR",
          "": "PCI-199",
          "kyb": 349013,
          "__1": "",
          "__2": ""
        }
];

export default Honda;